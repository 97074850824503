import { render, staticRenderFns } from "./AddNpc.vue?vue&type=template&id=d5fd0ac8&scoped=true"
import script from "./AddNpc.vue?vue&type=script&lang=js"
export * from "./AddNpc.vue?vue&type=script&lang=js"
import style0 from "./AddNpc.vue?vue&type=style&index=0&id=d5fd0ac8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5fd0ac8",
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QTab,QTabPanels,QTabPanel,QForm,QInput,QIcon,QTooltip,QBtnToggle,QBtn,QCheckbox,QDialog,QField});
